:root {
  --color-blue: #008cc1;
  --color-blue-02: #027dbb;
  --color-blue-03: #013e5d;
  --color-blue-04: #ccebfa;
  --color-blue-05: #009ef7;

  --color-white: #ffffff;

  --color-grey: #637381;
  --color-grey-02: #919eab14;
  --color-grey-03: #212b36;
  --color-grey-04: #919eab29;
  --color-grey-05: #cfcfcf;

  --color-grey-06: #cececf;
  --color-grey-07: #b3b2b3;
  --color-grey-08: #F5F8FA;
  --color-grey-09: #eef4f6;

  --color-black: #414042;

  --color-green: #50cd89;
  --color-green-02: #02B7AA;
  --color-green-03: #079b90;

  --color-green-04: #00b7aa;
  --color-green-05: #ccf1ee;

  --color-yellow: #ffc700;
  --color-red: #ea3a3d;
  --color-red-02: #fef5f6;
  --sx-color--grey: #bcbec0;
  --sx-color--blue: #008cc1;
  --sx-color--blue-02: #2e9ec8;
  --sx-color--blue-03: #18578c;
  --sx-font--poppins: "Poppins", sans-serif;
  --sx-font--hedley: "hedley-new-web", sans-serif;
}
